import React, { Component } from 'react'

export default class Next extends Component {

  render(props) {
    return (
        <div onClick={this.props.click} className={`next-wrapper shaken${this.props.shaken}`}>
            <span className="next">{this.props.instructions}</span>
            <div className="next-line"></div>
        </div>
    )
  }
}
