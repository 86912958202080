import React, { Component } from 'react'

export default class Loading extends Component {
  render(props) {
    return (
      <div className={`loading ${this.props.loading}`}>
        <div className="spinner">
          <img src={`${this.props.root}/graphics/loader.gif`} alt="Loading" />
        </div>
      </div>
    )
  }
}
