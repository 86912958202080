export default class Gradient {
    constructor(ctx, canvas, width, height, color1, color2, color3, color4) {
        this._ctx = ctx;
        this._canvas = canvas;
        this._width = width;
        this._height = height;
        this._color1 = color1;
        this._color2 = color2;
        this._color3 = color3;
        this._color4 = color4;
        this._render_color_1 = color1;
        this._render_color_2 = color2;
        this._grad = null;
    }

    switch(pink) {
        if (pink) { 
             this._render_color_1 = this._color1;
             this._render_color_2 = this._color2;
        } else {
             this._render_color_1 = this._color3;
             this._render_color_2 = this._color4;
        }
    }

    draw() {
        this._grad = this._ctx.createRadialGradient(this._width / 2, this._height / 2, 0, this._width / 2, this._height / 2, this._height / 5);
        this._grad.addColorStop(0, this._render_color_1);
        this._grad.addColorStop(1, this._render_color_2);
        this._ctx.fillStyle = this._grad;
        this._ctx.fillRect(0, 0, this._width, this._height);
    }
};
