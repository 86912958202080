import React, { Component } from 'react';
import { isMobile } from 'mobile-device-detect';

export default class Instructions extends Component {
  render(props) {
    return (
      <div className={`shake-wrapper jiggle${isMobile}`} onClick={this.props.click}>
        <img className={`shake shaken${this.props.shaken}`} src={`${this.props.root}/graphics/${this.props.permission ? 'shake_for_message.png' : 'tap_for_message.png'}`} alt="shaken" />
        <span className={`shake-instuctions shaken${this.props.shaken}`}>{this.props.instructions}</span>
      </div>
    )
  }
}
