export default class Data {

    constructor() {
  
        this.message_1 = ["You're", "totally", "my jam"];
        this.message_2 = ["Words can't", "express the", "fillings I", "have for you"];
        this.message_3 = ["I doughnut", "know what", "I'd do", "without you"];
        this.message_4 = ["I have a", "hole lotta", "love", "for you"];
        this.message_5 = ["These", "doughnuts", "are just a", "round-about", "way to say", "I love you"];
        this.message_6 = ["You make", "my world", "go round"];
        this.message_7 = ["I doughnut", "think it's", "possible to", "love you any", "more than", "I do"];
        this.message_8 = ["Your love's", "got me", "looking", "so glazy", "right now"];
        this.message_9 = ["Roses are", "red violets", "are blue", "doughnuts", "are better", "than dinner", "for two"];
        this.message_10 = ["Words can't", "explain", "how much", "I love you", "But", "doughnuts", "come close"];
        this.message_11 = ["You're the", "sprinkles", "on my", "doughnut"];
        this.message_12 = ["You deserve", "the world.", "For now,", "here's", "the next", "best thing;", "doughnuts"];
        this.message_13 = ["My love", "for you is", "round like", "a doughnut,", "it has no", "beginning", "or end"];
        this.message_14 = ["Nothing else", "matters as", "long as we", "have each", "other...and", "doughnuts"];
        this.message_15 = ["If the key to", "one's heart", "is through", "the stomach.", "Doughnuts", "unlock all"];
        this.message_16 = ["All you need", "is love...and", "doughnuts"];
        this.message_17 = ["Life", "without", "you is like", "an empty", "box of", "doughnuts"];
        this.message_18 = ["Who needs", "roses when", "you can buy", "doughnuts", "by the dozen"];
        this.message_19 = ["Nothing", "beats", "hearing", "those three", "words...", "Krispy Kreme", "doughnuts"];
        this.message_20 = ["The best", "things in", "life are", "even better", "with", "doughnuts"];
        this.message_21 = ["Real love", "stories", "start with", "doughnuts"];
        this.message_22 = ["If love", "is blind,", "why do", "doughnuts", "look so", "good?"];
        this.message_23 = ["Love isn't", "heart-shaped", "It's", "doughnut-", "shaped."];
        this.message_24 = ["True love", "never dies.", "It only gets", "stronger", "the more", "doughnuts", "you give"];
        this.message_25 = ["Everyone", "buys flowers", "But I know", "you'll prefer", "doughnuts"];
        this.message_26 = ["If our", "love lasts", "forever,", "We're gonna", "need more", "doughnuts"];
        this.message_27 = ["Real love", "means", "trusting", "someone...", "to buy you", "doughnuts"];
        this.message_28 = ["Love comes", "when you", "least", "expect it.", "Doughnuts", "can be", "delivered", "whenever"];
        this.message_29 = ["They ran out", "of roses. But", "I was able", "to pick the", "best dozen"];
        this.message_30 = ["Kiss me now,", " kiss me quick,", "Here's some", "doughnuts,", "take your", "pick"];
        this.message_31 = ["Love me", "tender,", "love me true,", "Here's some", "doughnuts,", "Just for you"];
        this.message_32 = ["You're so", "pretty I'm", "lost for", "words. So", "here's some", "doughnuts", "instead"];
        this.message_33 = ["The best", "thing I love", "about you?", "You're always", "happy to", "share"];
        this.message_34 = ["What's mine", "is yours,", "what's yours", "is mine.", "Doughnuts", "included"];
        this.message_35 = ["When you", "love each", "other, you", "share", "everything.", "Especially", "doughnuts"];
        this.message_36 = ["Doughnut", "go breaking", "my heart"];
        this.message_37 = ["You are the", "Krispy", "to my Kreme"];

        this.message_38 = ["I can't fight", "this filling", "anymore"];//
        this.message_39 = ["I think you're", "aglazing"];//
        this.message_40 = ["I love you", "with my", "hole heart"];//
        this.message_41 = ["I think you're", "a-dough-rable"];//
        this.message_42 = ["I'm doughnuts", "about you"];//
        this.message_43 = ["I love you", "doughhhh much"];//
        this.message_44 = ["I love you", "as much I love doughnuts", "...that's a lot"];//
        this.message_45 = ["Doughnut change", "a thing", "about you"];//
        this.message_46 = ["You're like", "a doughnut;", "holesome and sweet"];
        this.message_47 = ["We belong", "together like", "sprinkles on", "doughnuts"];//
        this.message_48 = ["You had me", "at doughnuts"];//

        this._message_container = [ 
                                    this.message_1,
                                    this.message_2,
                                    this.message_3,
                                    this.message_4,
                                    this.message_5,
                                    this.message_6,
                                    this.message_7,
                                    this.message_8,
                                    this.message_9,
                                    this.message_10,
                                    this.message_11,
                                    this.message_12,
                                    this.message_13,
                                    this.message_14,
                                    this.message_15,
                                    this.message_16,
                                    this.message_17,
                                    this.message_18,
                                    this.message_19,
                                    this.message_20,
                                    this.message_21,
                                    this.message_22,
                                    this.message_23,
                                    this.message_24,
                                    this.message_25,
                                    this.message_26,
                                    this.message_27,
                                    this.message_28,
                                    this.message_29,
                                    this.message_30,
                                    this.message_31,
                                    this.message_32,
                                    this.message_33,
                                    this.message_34,
                                    this.message_35,
                                    this.message_36,
                                    this.message_37,
                                    
                                    this.message_38,
                                    this.message_39,
                                    this.message_40,
                                    this.message_41,
                                    this.message_42,
                                    this.message_43,
                                    this.message_44,
                                    this.message_45,
                                    this.message_46,
                                    this.message_47,
                                    this.message_48
                                ];
    } 
};