import React, { Component } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { isMobile } from 'mobile-device-detect';

export class Form extends Component {

  constructor(props) {
    super();

    this.state = {
      user_name: "",
      valentine_user_name: ""
    }

    this.validator = new SimpleReactValidator({
      messages: {
          default: 'This field may only contain letters, dashes, and spaces.'
      },
    });
    this.submitForm = this.submitForm.bind(this);
    this.setStateFromInput = this.setStateFromInput.bind(this);
  }

  submitForm(e) {
    e.preventDefault();

    //Getting permission 
    if (this.validator.allValid()) {

      window.location.assign(`?${this.props.form_to}=${this.state.valentine_user_name}&${this.props.form_from}=${this.state.user_name}`);

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  setStateFromInput (event) {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  render() {
    return (
      <div className="krispy_kreme_valentines_form">
        <img className="love_message_maker" src={`${this.props.root}/graphics/love_message_maker.png`} alt="Love Message Maker" />
        <form id="Krispy_Kreme_Valentines">
          <legend>Deep in love but words got you in a jam? Leave them to us.</legend>
          <div className="form-group">
            <label>Your name</label>
            <input className="form-control" name="user_name" value={this.state.user_name} onChange={this.setStateFromInput} placeholder="" />
            {this.validator.message('Your name', this.state.user_name, 'required|alpha_num_dash_space|max:15')}
          </div>
          <div className="form-group">
            <label>Your Valentine's name</label>
            <input className="form-control" name="valentine_user_name" value={this.state.valentine_user_name} onChange={this.setStateFromInput} placeholder="" />
            {this.validator.message('Valentine Name', this.state.valentine_user_name, 'required|alpha_num_dash_space|max:15')}
          </div>
          <div className="form-button">
            <button className="krispy_button" onClick={this.submitForm}>Create Message</button>
          </div>        
        </form>
      </div>
    )
  }
}

export default Form
