import { TweenMax, Expo } from 'gsap';

export default class Dougnut {
    constructor(ctx, x, y, width, height, rotation, img, second_image, maxHeight) {
        this._ctx = ctx;
        this._x = x;
        this._y = y;
        this._ag = 0.12;
        this._height = height;
        this._width = width;
        this._rotation = rotation;
        this._end_rotation = rotation;
        this._opacity = 0;
        this._end_opacity = 1;
        this.img = new Image();
        this.img.src = img;
        this._second_image = new Image();
        this._second_image.src = second_image;
        this._loaded = false;
        this._image_container = [this.img, this._second_image];

        for(var i = 0; i < this._image_container.length; ++i) {
            this._image_container[i].onload = function() {
                if(i === this._image_container.length) {
                    this._loaded = true;
                    this.draw();
                }
            }.bind(this);
        }
         
        this._pink = true;
        this._maxHeight = maxHeight;
    }

    draw() {
        this._ctx.save();
        this._ctx.globalAlpha = this._opacity;
        this._ctx.translate(this._x + this._width / 2, this._y + this._height / 2);
        this._ctx.imageSmoothingEnabled = true;
        this._ctx.imageSmoothingQuality = "high"
        this._ctx.rotate(this._rotation * Math.PI / 180);
        this._ctx.translate(-this._x - this._width / 2, -this._y - this._height / 2);
        if(!this._pink) {
            this._ctx.drawImage(this._second_image, this._x, this._y, this._width, this._height);
        } else {
            this._ctx.drawImage(this.img, this._x, this._y, this._width, this._height);   
        }
        this._ctx.restore();
    }

    switch() {
        this._rotation = Math.random() * 720;
        this._opacity = 0;
        let tween = TweenMax.to(this, (Math.random() * 2.5), {
            _rotation: this._end_rotation,
            _opacity: this._end_opacity,
            delay: 1,
            ease: Expo.easeInOut
        });
        this._pink = !this._pink;
    }

    update() {
       // this._x += this._ag;
        if(this._y < 0 - this._height) {
            this._y = this._maxHeight + this._height;
            this.x = (this._x + (Math.random() * 10) - 10);
        }
        this._y -= this._ag;
        this.draw();
    }
};
