import React, { Component } from 'react'
import Canvas from '../Canvas/KrispyCanvas';
import RenderCanvas from '../Canvas/FacebookCanvas';
import Shake from 'shake.js';
import Next from '../Next/Next';
import Instructions from '../Instructions/Instructions';
import Loading from '../Loading/Loading';
import { isMobile } from 'mobile-device-detect';

export default class Shaker extends Component {

    constructor(props) {
        super(props);
        this.shakeEventDidOccur = this.shakeEventDidOccur.bind(this);
        
        this.state = {
            shaken: false,
            _renderMessage: [],
            _processing : false,
            _permission: false
        }

        this._krispyCanvas = null;
        this._facebookCanvas = null;

        this.renderCanvas = this.renderCanvas.bind(this);
        this.renderCanvasDownload = this.renderCanvasDownload.bind(this);
        this.downloadULR = this.downloadULR.bind(this);
        this.sendData = this.sendData.bind(this);
        this.formEncode = this.formEncode.bind(this);
        this.shareToFacebook = this.shareToFacebook.bind(this);
    }
    
    componentDidMount() {
        //this.checkPermission()
    }

    checkPermission() {

    }

    shakeEventDidOccur(e) {
        this.setState({
            shaken: true
        });
        this._krispyCanvas.shake();
    }

    renderCanvasDownload() {
        if (!this.state._processing) {
            this.setState({
                _processing: true
            });
            this._facebookCanvas.renderCanvas(this._krispyCanvas._rendered_message, this._krispyCanvas._pink, function (data) {
                if (data != null) {
                    this.sendData(data, false);
                }
            }.bind(this));
        }   
    }

    renderCanvas() {
        if(!this.state._processing) {
            this.setState({
                _processing : true
            });
            this._facebookCanvas.renderCanvas(this._krispyCanvas._rendered_message, this._krispyCanvas._pink, function(data) {
                if (data != null) {
                    this.sendData(data, true);
                }
            }.bind(this));
        }   
    }

    formEncode(obj) {
        var str = [];
        for (var p in obj)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        return str.join("&");
    }

    sendData(data, facebook) {
        let imagefile = data.replace("data:image/jpeg;base64,", "");
        let xhr = new XMLHttpRequest();
        xhr.open("POST", this.props.render, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onreadystatechange = function () {

            console.log(xhr)
            
            if (xhr.readyState === 4 && xhr.status === 200) {
                this.setState({
                    _processing: false
                });
                if (facebook) {
                    this.shareToFacebook(xhr.response);
                } else {
                    this.downloadULR(xhr.response);
                }

            } else {
                console.error('error processing image');
            }

        }.bind(this);
        
        xhr.send(imagefile);    
    }

    downloadULR(url) {
        window.location.href = `${url}`;
    }

    shareToFacebook(url) {
        window.location.href = `https://www.facebook.com/sharer/sharer.php?u=${url}`;     
    }
    
    render() {
        return (
            <div className={'wrapper'}>
                <Loading loading={this.state._processing} root={this.props.root}/>
                <Instructions click={this.shakeEventDidOccur} shaken={this.state.shaken} permission={this.state._permission} instructions={isMobile ? `${this.state._permission ? 'Shake for message' : 'Tap for message'}` : "Generate message"} root={this.props.root} />
                <Next click={this.shakeEventDidOccur} shaken={this.state.shaken} instructions={isMobile ? `${this.state._permission ? 'shake for new message' : 'tap for new message'}` : "Generate new message"} />
                <Canvas to={this.props.to} from={this.props.from} ref={(krispyCanvas) => { this._krispyCanvas = krispyCanvas; }} root={this.props.root}/>
                <RenderCanvas to={this.props.to} from={this.props.from} cta_text={this.props.cta_text} ref={(facebookCanvas) => { this._facebookCanvas = facebookCanvas; }} root={this.props.root}/>
                {!this.state.shaken ? null : <div className="button_wrapper">
                    <a onClick={this.renderCanvas} className="krispy_button share">Share<span></span></a>
                    <a onClick={this.renderCanvasDownload} className="krispy_button download">Download<span></span></a>
                    <a href={this.props.cta} target="_blank" className="krispy_button">SEND DOUGHNUTS</a>
                </div>}
            </div>
        )
    }
}
