export default class Text {
    constructor(ctx, text, color, dark_color, size, x, y, align, font, alpha) {
        this._ctx = ctx;
        this._text = text;
        this._size = size;
        this._x = x;
        this._y = y;
        this._align = align;
        this._color = color;
        this._color_dark = dark_color;
        this._alpha = alpha;
        this._pink = true;
        this._font = font;
        this.draw();
    }

    draw() {
        this._ctx.save();
        this._ctx.font = `${this._size}px ${this._font}`;
        if(!this._pink) {
            this._ctx.fillStyle = `rgba(${this._color_dark} , ${this._alpha})`;
        } else {
            this._ctx.fillStyle = `rgba(${this._color} , ${this._alpha})`;
        }
        this._ctx.textAlign = this._align;
        this._ctx.fillText(this._text, this._x, this._y);
        this._ctx.restore();
    }

    switch() {
        this._pink = !this._pink;
    }

    update() {
        this.draw();
    }
};
