import React, { Component } from 'react'

export class Header extends Component {
  render(props) {
    return (
      <header className={`krispy_kreme_header ${this.props.locked ? 'locked' : ''}`}>  
        <a href={this.props.url} target="_blank" rel="noopener noreferrer">
          <img src={`${this.props.root}/graphics/krispy_kreme_logo.png`} alt="Krispy Kreme" />
        </a>
      </header>
    )
  }
}

export default Header
