import React, { Component } from 'react'
import Tile from './Tile';
import Doughnut from './Doughnut';
import Text from './Text';
import Gradient from './Gradient';
import Data from '../Shaker/Data/Data';
import { isMobile } from 'mobile-device-detect';

export default class KrispyCanvas extends Component {

    constructor(props) {
        super(props);

        this._letters = [   "A","B","C","D","E","F","G",
                            "H","I","J","K","L","M","N","O","P",
                            "Q","R","S","T","U","V","W","X","Y","Z","Blank_1","Blank_2","Doughnut","Heart"];

        this._animationRequest = null;
        this._canvas = null;
        this._ctx = null;
        this._tile_container = [];
        this._doughnut_container = [];
        this._dpr = 1;
        this._grad = null;

        this.state = {
            _width: window.innerWidth,
            _height: window.innerHeight,
            _content: false,
            _size_factor: 1,
            _center_offset: 2
        }

        this._tileNumber = 100;
        this._tileSize = 30;
        this._from_y = 360;
        this._tileDistance = 40;
        this._renderedImage = null;
        this._pink = true;
        this._from = null;
        this._to = null;
        this._inital_shake = false;
        this._resized = false;
        this._resize_timer = null;
        this._logo_space = 100;

        /* Functions */
        this.animate = this.animate.bind(this);
        this.shake = this.shake.bind(this);
        this.dist = this.dist.bind(this);
        this.rescale = this.rescale.bind(this);
        this.formWords = this.formWords.bind(this);
        this.shakeOut = this.shakeOut.bind(this);
        this.putBack = this.putBack.bind(this);

        //Set data
        this._data = new Data();
        this._message_container = this._data._message_container.slice(0);

        this._rendered_message = [];
    }

    componentDidMount() {

        this.setState({
            _width: window.innerWidth,
            _height: window.innerHeight
        });

        this._ctx = this._canvas.getContext('2d');
        this._canvas.setAttribute('width', this.state._width);
        this._canvas.setAttribute('height', this.state._height);
        
        window.addEventListener('resize', this.rescale);
        this.rescale();

        let font = window.innerWidth > 1023 ? 58 : 38;
        let size_factor = window.innerWidth > 1023 ? 1.5 : 1;
        this._logo_space = window.innerWidth > 1023 ? 200 : 100;

        this._from = new Text(this._ctx, `${this.props.to},`, '212, 49, 83', '227, 186, 207', font, this.state._width / 2, 110, "center", "FilmotypeLucky", 0);
        this._to = new Text(this._ctx, `-${this.props.from}`, '212, 49, 83', '227, 186, 207', font, this.state._width / 2, this._from_y, "center", "FilmotypeLucky", 0);

        this._grad = new Gradient(this._ctx, this._canvas, this.state._width, this.state._height, "#f0dae6", "#e3bacf", "#503124", "#472719");

        for (let i = 0; i < this._tileNumber; i++) {
            
            let tile = new Tile(
                this._ctx,
                this.state._width/2,
                this.state._height/2,
                this._tileSize * size_factor,
                this._tileSize * size_factor,
                Math.random() * 360,
                `${this.props.root}/tiles/${this._letters[Math.floor(Math.random() * this._letters.length)]}.png`, 
                "a",
                0,
                false
            );

            this.dist(tile);
            this._tile_container.push(tile);
        }

        let dt = window.innerWidth > 1023 ? true : false;
        let doughnuts = dt ? 6 : 3;

        for (let j = 0; j < doughnuts; j++) {

            let start_x = 0;
            let start_y = this.state._height / 2.5 * j;
            let dPos = [[10, 68], [this.state._width - 200, 32], [50, 709], [this.state._width - 300, 569], [this.state._width / 5, this.state._height - 100], [this.state._width - 400, this.state._height]];

            if (doughnuts === 3) {
                /* Mobile */
                if (j % 2 === 0) {
                    start_x = this.state._width / 10;
                } else {
                    start_x = this.state._width / 1.3
                }
               
                start_x = (start_x - Math.floor(Math.random() * this.state._width / 5));

            } else {
                start_x = dPos[j][0];
                start_y = dPos[j][1]
            }           

            let doughnut = new Doughnut(    this._ctx, 
                                            start_x, 
                                            start_y,
                                            120, 110, 
                                            ((Math.random() * 40) - 10), `${this.props.root}/graphics/heart_doughnut.png`, `${this.props.root}/graphics/heart_doughnut_choc.png`,
                                            this.state._height);

            this._doughnut_container.push(doughnut);
        }

        this._animationRequest = window.requestAnimationFrame(this.animate);
    }

    dist(tile) {

        let centerx = Math.floor(this.state._width / 2);
        let centery = Math.floor(this.state._height / 2);
        let distx = Math.floor(this.state._width / 2);
        let disty = Math.floor(this.state._height / 2);
        let distance = 0;
        
        // No tiles in a radius of 150 px of the center
        while(distance === 0 || distance < 150) {
            //Check centre 
            distx = Math.floor(Math.random() * this.state._width) - tile._width;
            disty = Math.floor(Math.random() * this.state._height) - tile._height;
            distance = Math.floor(Math.sqrt(Math.pow(distx - centerx, 2) + Math.pow(disty - centery, 2)));
            tile._x = distx;
            tile._y = disty;
        }

        //If there are tiles within a 50px distance - remove from array
        for (let i = 0; i < this._tile_container.length; i++) {
            let other = this._tile_container[i];
            let distxp = other._x - distx;
            let distyp = other._y - disty;
            let c = Math.floor(Math.sqrt(distxp * distxp + distyp * distyp));

            if (c < this._tileDistance) {
                this._tile_container.splice(i, 1);
            }
        }

        //If the tiles are within 200px from logo - remove
        for (let i = 0; i < this._tile_container.length; i++) {
            let other = this._tile_container[i];
            let distxp = other._x - this.state._width / 2;
            let distyp = other._y - 0;
            let c = Math.floor(Math.sqrt(distxp * distxp + distyp * distyp));
            if (c < this._logo_space) {
                this._tile_container.splice(i, 1);
            }
        }
    }

    shake() {
        
        for (let i = 0; i < this._tile_container.length; i++) {
            this._tile_container[i].shake();
        }

        for (let j = 0; j < this._doughnut_container.length; j++) {
            this._doughnut_container[j].switch();
        }

        this._from.switch();
        this._to.switch();

        this._pink = !this._pink;

        this._grad.switch(this._pink);

        this.formWords();

        this.setState({
            _content: true
        });
    }

    formWords() {

        //If empty repopulate
        if(this._message_container.length === 0) {
            this._message_container = this._data._message_container.slice(0);
        } 

        let randomi = Math.floor(Math.random() * this._message_container.length);
        let random_message = this._message_container[randomi];

        this._message_container.splice(randomi, 1);
        this._rendered_message = random_message;

        for(let i = 0; i < random_message.length; i++) {

            let tileproto = random_message[i];

            let offset = 0;

            for (let k = 0; k < random_message[i].length; k++) {
                let tileproto = random_message[i][k];
                if (tileproto === "'" || tileproto === "." || tileproto === "," || tileproto === "-" || tileproto === "?" || tileproto === ";") {
                    
                    switch (tileproto) {
                        case "'":
                            offset += .45;
                            break;
                        case ".":
                            offset += .4;
                            break;
                        case ",":
                            offset += .25;
                            break;
                        case "-":
                            offset += .65;
                            break;
                        case "?":
                            offset += .3;
                            break;
                        case ";":
                            offset += .25;
                            break;
                        default:
                            break;
                    }        
                }
            }
            

            let xpos = ((tileproto.length * (this._tileSize + 5)) / 2) * this.state._size_factor;
            xpos = (xpos - (offset * this._tileSize));
            let ypos = ((this._rendered_message.length * ((this._tileSize * this.state._size_factor) + 5)) / 2);

            let distx = 0;

            for (let j = 0; j < random_message[i].length; j++) {

                let tileproto = random_message[i][j].toUpperCase();
                let distx_factor = 1;

                if(tileproto === " ") {
                    distx_factor = .75;
                } else if (tileproto === "'" || tileproto === "." || tileproto === "," || tileproto === "-" || tileproto === "?" || tileproto === ";") {

                    let image_path;

                    switch (tileproto) {
                        case "'":
                            distx_factor = .45;
                            image_path = this._pink ? `${this.props.root}/punctuation/apos.png` : `${this.props.root}/punctuation/apos_pink.png`
                            break;
                        case ".":
                            distx_factor = .4;
                            image_path = this._pink ? `${this.props.root}/punctuation/period.png` : `${this.props.root}/punctuation/period_pink.png`
                            break;
                        case ",":
                            distx_factor = .25;
                            image_path = this._pink ? `${this.props.root}/punctuation/comma.png` : `${this.props.root}/punctuation/comma_pink.png`
                            break;
                        case "-":
                            distx_factor = .65;
                            image_path = this._pink ? `${this.props.root}/punctuation/dash.png` : `${this.props.root}/punctuation/dash_pink.png`
                            break;
                        case "?":
                            distx_factor = .3;
                            image_path = this._pink ? `${this.props.root}/punctuation/question_mark.png` : `${this.props.root}/punctuation/question_mark_pink.png`
                            break;
                        case ";":
                            distx_factor = .25;
                            image_path = this._pink ? `${this.props.root}/punctuation/semi.png` : `${this.props.root}/punctuation/semi_pink.png`
                            break;
                        default:
                            break;
                    }                 

                    let tile = new Tile(
                        this._ctx,
                        (((this.state._width / this._dpr) / 2) - xpos) + (distx * (this._tileSize + 5)) * this.state._size_factor,
                        (((this.state._height / this._dpr) / this.state._center_offset) - ypos) + (i * ((this._tileSize + 10) * this.state._size_factor)),
                        this._tileSize * this.state._size_factor,
                        this._tileSize * this.state._size_factor,
                        Math.random() * 2,
                        image_path,
                        `'`,
                        0,
                        true
                    );

                    this._tile_container.push(tile);

                } else {
                    let tile = new Tile(
                        this._ctx,
                        (((this.state._width / this._dpr) / 2) - xpos) + (distx * (this._tileSize + 5)) * this.state._size_factor,
                        (((this.state._height / this._dpr) / this.state._center_offset) - ypos) + (i * ((this._tileSize + 10) * this.state._size_factor)),
                        this._tileSize * this.state._size_factor,
                        this._tileSize * this.state._size_factor,
                        Math.random() * 2,
                        `${this.props.root}/tiles/${tileproto}.png`,
                        `${tileproto}`,
                        0,
                        false
                    );
                    this._tile_container.push(tile);
                }       
                
                /* Position to and from */
                if (j === random_message.length) {
                    this._from._y = ((this.state._height / this._dpr) / this.state._center_offset) - (((i/2) * ((this._tileSize * this.state._size_factor)) + (85 * this.state._size_factor)));
                    this._to._y = ((this.state._height / this._dpr) / this.state._center_offset) + (((i/2) * (((this._tileSize + 10) * this.state._size_factor)) + (105 * this.state._size_factor)));
                }

                distx += distx_factor;
            }
        }

        for(var k = 0; k < this._tile_container.length; k ++) {
            this._tile_container[k].init();
        }
    }

    rescale() {

        this._dpr = window.devicePixelRatio || 1;
        let rect = this._canvas.getBoundingClientRect();
        let width = Math.round(this._dpr * rect.right) - Math.round(this._dpr * rect.left);
        let height = Math.round(this._dpr * rect.bottom) - Math.round(this._dpr * rect.top);
        let breakpoints = width / this._dpr;
        let vbreakpoints = height / this._dpr;
        let size_factor = 1;
        let center_offset = 2;

        if (breakpoints <= 320) {
            size_factor = .6;
            center_offset = 2.6;
        } else if (breakpoints >= 321 && breakpoints <= 768) {
            size_factor = .7;
            center_offset = 2.5;
        } else if (breakpoints >= 769 && breakpoints <= 1024) {
            size_factor = 1.2;
            center_offset = 2.2;
        } else if (breakpoints >= 1025 && breakpoints <= 1919) {
            size_factor = 1.3;
            center_offset = 2.2;
        } else if (breakpoints > 1920) {
            size_factor = 1.6;
            center_offset = 2;
        }

        if (!isMobile) {
            if (vbreakpoints < 550) {
                size_factor = .5;
                center_offset = 2;
            } else if (vbreakpoints >= 551 && vbreakpoints <= 600) {
                size_factor = .7;
                center_offset = 2;
            } else if (vbreakpoints >= 601 && vbreakpoints <= 700) {
                size_factor = 0.8;
                center_offset = 2;
            } else if (vbreakpoints >= 701 && vbreakpoints <= 800) {
                size_factor = 0.9;
                center_offset = 2;
            } else if (vbreakpoints >= 801 && vbreakpoints <= 900) {
                size_factor = 1;
                center_offset = 2;
            }
        }

        if (!this._resized && this.state._content) {
            this.shakeOut();
            this._resized = true;
        }

        this.setState({
            _width: width,
            _height: height,
            _size_factor: size_factor,
            _center_offset: center_offset
        });

        this._canvas.setAttribute('width', width);
        this._canvas.setAttribute('height', height);
    }

    shakeOut(){

        for (var i = 0; i < this._tile_container.length; i++) {
            this._tile_container[i].resize();
        }

        this._resize_timer = setTimeout(function () {
            this.putBack();
        }.bind(this), 1000);
    }

    putBack() {
        this.shake();
        this._resized = false;
    }
    
    animate() {

        this._ctx.save();
        this._ctx.scale(this._dpr, this._dpr);
        this._ctx.clearRect(0, 0, this.state._width / this._dpr, this.state._height / this._dpr);

        this._grad._width = this.state._width / this._dpr;
        this._grad._height = this.state._height / this._dpr;
        this._grad.draw();

        if (this.state._content) {
            
            for (let j = 0; j < this._doughnut_container.length; j++) {
                this._doughnut_container[j].update();
            }

            this._from._alpha = 1;
            this._to._alpha = 1;
            this._from._x = (this.state._width / this._dpr) / 2;
            this._to._x = (this.state._width / this._dpr) / 2;
            this._from.draw();
            this._to.draw();
        } else {
            for (let k = 0; k < this._tile_container.length; k++) {
                this._tile_container[k]._opacity = 1;
                this._tile_container[k].draw();
            }
        }

        for(let i = 0; i < this._tile_container.length; i++) {
            
            if (this._tile_container[i]._x > this.state._width / this._dpr || this._tile_container[i]._x < 0 || this._tile_container[i]._y > this.state._height / this._dpr || this._tile_container[i]._y < 0) {
                this._tile_container.splice(i, 1);
            }

            if (this._tile_container[i]) {
                this._tile_container[i].update(1);
            }
        }

        this._ctx.restore();
        this._animationRequest = window.requestAnimationFrame(this.animate);
    }

    render() {
        return (
            <div>
                <canvas ref={(canvas) => { this._canvas = canvas; }} />
            </div>
        )
    }
}
