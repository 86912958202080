import React, { Component } from 'react';
import Header from './Components/Header/Header';
import Form from './Components/Form/Form';
import Shaker from './Components/Shaker/Shaker';
import Config from './config.json';
import './App.scss';

class App extends Component {

  constructor(props) {
    super();

    this.state = {
      _form : true,
      _from : "",
      _to : "",
      _locked_header: false
    }

    this.getParameterByName = this.getParameterByName.bind(this);
    this._cta = Config.region.cta_dougnuts;
    this._cta_text = Config.region.cta_text;
    this._home_page = Config.region.kk_homepage;
    this._render = Config.region.image_render;
    this._root = Config.region.root_directory;
    this.rescale = this.rescale.bind(this);

  }

  componentDidMount() {
    let user_name = this.getParameterByName('user_name');
    let valentine_name = this.getParameterByName('valentine_name');

    window.addEventListener('resize', this.rescale);
    this.rescale();

    if (user_name != null && valentine_name != null) {

      this.setState({
        _form : false,
        _from: user_name,
        _to: valentine_name,
        _locked_header : true
      });

      this.shaker = React.createRef();
      
      setTimeout(() => {
        this.setState({
          _form : false
        })
        this.shaker.current.checkPermission();
      }, 500)

    } else {

      this.setState({
        _form: true
      });

    }
  }

  rescale() {
    
    // let inner_window_height = document.getElementById('root');
    // let height = window.innerHeight;
    // inner_window_height.setAttribute('style', `height: ${height}px`);
    // inner_window_height.setAttribute('style', `height: 100vh`);

    setTimeout( () => {
      this.height = window.innerHeight;
      let vh =  this.height  * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 500);
    
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    return (
      <div className="App">
        <Header url={this._home_page} root={this._root} locked={this.state._locked_header}/>
          { this.state._form ? 
            <Form form_to={'valentine_name'} form_from={'user_name'} root={this._root}/> 
              : 
            <Shaker ref={this.shaker} to={this.state._to} from={this.state._from} cta={this._cta} cta_text={this._cta_text} render={this._render} root={this._root} /> 
          }
      </div>
    );
  }
}

export default App;
