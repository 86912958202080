import React, { Component } from 'react'
import Tile from './Tile';
import Doughnut from './Doughnut';
import Text from './Text';
import KrispyImage from './KrispyImage';

export default class FacebookCanvas extends Component {

    constructor(props) {
        super(props);

        this._letters = [   "A","B","C","D","E","F","G",
                            "H","I","J","K","L","M","N","O","P",
                            "Q","R","S","T","U","V","W","X","Y","Z","Blank_1","Blank_2","Doughnut","Heart"];

        this._canvas = null;
        this._ctx = null;
        this._tile_container = [];
        this._doughnut_container = [];
        this._loaded_container = [];

        this.state = {
            _width: 1080,
            _height: 1350
        }

        this._dpr = 1;
        this._tileNumber = 50;
        this._tileSize = 60;
        this._to_y = 0;
        this._from_y = 0;
        this._tileDistance = 40;
        this._renderedImage = null;
        this._pink = true;
        this._from = null;
        this._to = null;
        this._krispy = null;
        this._rendered_message = [];
        this._image = null;

        /* Functions */
        this.animate = this.animate.bind(this);
        this.formWords = this.formWords.bind(this);
        this.renderCanvas = this.renderCanvas.bind(this);
    }

    componentDidMount() {

        this.setState({
            _width: 1080,
            _height: 1350
        });

        this._ctx = this._canvas.getContext('2d');

        this._from = new Text(this._ctx, `${this.props.to},`, '212, 49, 83', '227, 186, 207', 80, this.state._width / 2, 110, "center", "FilmotypeLucky", 1);
        this._to = new Text(this._ctx, `-${this.props.from}`, '212, 49, 83', '227, 186, 207', 80, this.state._width / 2, this._from_y, "center", "FilmotypeLucky", 1);
        this._krispy = new Text(this._ctx, this.props.cta_text, '212, 49, 83', '227, 186, 207', 40, (this.state._width / 2) - 50, this.state._height - 85, "left", "BebasNeue", 1);
        this._image = new KrispyImage(this._ctx, 50, 1215, 224, 77, `${this.props.root}/graphics/krispy_kreme_logo.png`);

        let dPos = [[10, 68], [958, 32], [20, 709], [950, 569], [804, 1057]];

        for (var j = 0; j < 5; j++) {

            let doughnut = new Doughnut(this._ctx,
                dPos[j][0], dPos[j][1],
                120, 110,
                ((Math.random() * 40) - 10), `${this.props.root}/graphics/heart_doughnut.png`, `${this.props.root}/graphics/heart_doughnut_choc.png`,
                this.state._height);

            this._doughnut_container.push(doughnut);
        }
    }

    formWords(callback) {
        
        for (var i = 0; i < this._rendered_message.length; i++) {

            let tileproto = this._rendered_message[i];
            
            let offset = 0;

            for (var k = 0; k < this._rendered_message[i].length; k++) {
                let tileproto = this._rendered_message[i][k];
                if (tileproto === "'" || tileproto === "." || tileproto === "," || tileproto === "-" || tileproto === "?" || tileproto === ";") {

                    switch (tileproto) {
                        case "'":
                            offset += .45;
                            break;
                        case ".":
                            offset += .4;
                            break;
                        case ",":
                            offset += .25;
                            break;
                        case "-":
                            offset += .65;
                            break;
                        case "?":
                            offset += .3;
                            break;
                        case ";":
                            offset += .25;
                            break;
                        default:
                            break;
                    }
                }
            }
            
            let xpos = ((tileproto.length * (this._tileSize + 5)) / 2);
            xpos = (xpos - (offset * this._tileSize));
            let ypos = ((this._rendered_message.length * (this._tileSize + 5)) / 2);
        
            let distx = 0;

            for (var j = 0; j < this._rendered_message[i].length; j++) {

                let tileproto = this._rendered_message[i][j].toUpperCase();
                
                let distx_factor = 1;

                if (tileproto === " ") {
                    distx_factor = 0.75;
                } else if (tileproto === "'" || tileproto === "." || tileproto === "," || tileproto === "-" || tileproto === "?" || tileproto === ";") {

                    let image_path;

                    switch (tileproto) {
                        case "'":
                            distx_factor = .45;
                            image_path = this._pink ? `${this.props.root}/punctuation/apos.png` : `${this.props.root}/punctuation/apos_pink.png`
                            break;
                        case ".":
                            distx_factor = .4;
                            image_path = this._pink ? `${this.props.root}/punctuation/period.png` : `${this.props.root}/punctuation/period_pink.png`
                            break;
                        case ",":
                            distx_factor = .25;
                            image_path = this._pink ? `${this.props.root}/punctuation/comma.png` : `${this.props.root}/punctuation/comma_pink.png`
                            break;
                        case "-":
                            distx_factor = .65;
                            image_path = this._pink ? `${this.props.root}/punctuation/dash.png` : `${this.props.root}/punctuation/dash_pink.png`
                            break;
                        case "?":
                            distx_factor = .3;
                            image_path = this._pink ? `${this.props.root}/punctuation/question_mark.png` : `${this.props.root}/punctuation/question_mark_pink.png`
                            break;
                        case ";":
                            distx_factor = .25;
                            image_path = this._pink ? `${this.props.root}/punctuation/semi.png` : `${this.props.root}/punctuation/semi_pink.png`
                            break;
                        default:
                            break;
                    }

                    let tile = new Tile(
                        this._ctx,
                        (((this.state._width / this._dpr) / 2) - xpos) + (distx * (this._tileSize + 5)),
                        (((this.state._height / this._dpr) / 2) - ypos) + (i * (this._tileSize + 10)),
                        this._tileSize * this._size_factor,
                        this._tileSize * this._size_factor,
                        Math.random() * 2,
                        image_path,
                        `'`,
                        1,
                        true
                    );
                    
                    this._tile_container.push(tile);

                } else {
                    let tile = new Tile(
                        this._ctx,
                        (((this.state._width / this._dpr) / 2) - xpos) + (distx * (this._tileSize + 5)),
                        (((this.state._height / this._dpr) / 2) - ypos) + (i * (this._tileSize + 10)),
                        this._tileSize * this._size_factor,
                        this._tileSize * this._size_factor,
                        Math.random() * 2,
                        `${this.props.root}/tiles/${tileproto}.png`,
                        "a",
                        1,
                        false
                    );
                    this._tile_container.push(tile);
                }       
                
                if (j === this._rendered_message.length) {
                    this._from_y = (this.state._height / 2) - ((this._rendered_message.length / 2) * (this._tileSize) + 150);
                    this._to_y = (this.state._height / 2) + ((this._rendered_message.length /2) * (this._tileSize) + 210);
                }

                distx += distx_factor;
            }
        }

        callback();
    }

    animate() {

        this._ctx.save();
        this._ctx.scale(this._dpr, this._dpr);
        this._ctx.clearRect(0, 0, this.state._width / this._dpr, this.state._height / this._dpr);
              
        this._grad = this._ctx.createRadialGradient((this.state._width / this._dpr) / 2, (this.state._height / this._dpr) / 2, 0, (this.state._width / this._dpr) / 2, (this.state._height / this._dpr) / 2, (this.state._height / this._dpr) / 5);
        
        if (this._pink) { 
            this._grad.addColorStop(0, "#f0dae6");
            this._grad.addColorStop(1, "#e3bacf");
        } else {
            this._grad.addColorStop(0, "#503124");
            this._grad.addColorStop(1, "#472719");
        }
    
        this._ctx.fillStyle = this._grad;
        this._ctx.fillRect(0, 0, this.state._width / this._dpr, this.state._height / this._dpr);

        for (var j = 0; j < this._doughnut_container.length; j++) {
            if(!this._pink){
                this._doughnut_container[j]._pink = false;
            }
            this._doughnut_container[j]._opacity = 1;
            this._doughnut_container[j].draw();
        }

        this._image.draw();

        this._to._y = this._to_y;
        this._to.draw();
        this._from._y = this._from_y;
        this._from.draw();
        this._krispy.draw();

        for(var i = 0; i < this._tile_container.length; i ++) {
            
            if (this._tile_container[i]._x > this.state._width / this._dpr || this._tile_container[i]._x < 0 || this._tile_container[i]._y > this.state._height / this._dpr || this._tile_container[i]._y < 0) {
                this._tile_container.splice(i, 1);
            }

            if (this._tile_container[i]) {
                this._tile_container[i]._width = this._tileSize;
                this._tile_container[i]._height = this._tileSize;
                this._tile_container[i].update(1);

            } else {
               
            }
        }

        this._ctx.restore();
    }

    renderCanvas(message, pink, callback) {
        this._rendered_message = message;
        
        if (pink) {
            this._from._pink = true;
            this._to._pink = true;
            this._krispy._pink = true;
            this._pink = true;
        } else {
            this._krispy._pink = false;
            this._from._pink = false;
            this._to._pink = false;
            this._pink = false;
        }

        this.formWords(function() {

            this.animate(); 

            setTimeout(function () {

                this._renderedImage = new Image();
                this._renderedImage.src = this._canvas.toDataURL('image/jpeg', 0.8);
                callback(this._renderedImage.src);

            }.bind(this), 2000);

        }.bind(this));
        
    }

    render() {
        return (
            <div className="facebook_canvas">
                <canvas ref={(canvas) => { this._canvas = canvas; }} width={this.state._width} height={this.state._height} />
            </div>
        )
    }
}
