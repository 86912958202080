export default class KrispyImage {
    constructor(ctx, x, y, width, height, img) {
        this._ctx = ctx;
        this._x = x;
        this._y = y;
        this._height = height;
        this._width = width;

        this.img = new Image();
        this.img.src = img;

        this._loaded = false;
       
        this.img.onload = function () {
            this.draw();
            this._loaded = true;
        }.bind(this);
    }

    draw() {
        this._ctx.save();
        this._ctx.translate(this._x + this._width / 2, this._y + this._height / 2);
        this._ctx.imageSmoothingEnabled = true;
        this._ctx.imageSmoothingQuality = "high";
        this._ctx.translate(-this._x - this._width / 2, -this._y - this._height / 2);
        this._ctx.drawImage(this.img, this._x, this._y, this._width, this._height);
        this._ctx.restore();
    }
};
