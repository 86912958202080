import { TweenMax, Expo } from 'gsap';

export default class Tile {
    constructor(ctx, x, y, width, height, rotation, img, letter, opacity, punctuation) {
        this._ctx = ctx;
        this._x = x;
        this._y = y;
        this._end_x = x;
        this._end_y = y;
        this._vx = 0;
        this._vy = 0;
        this._a = 0;
        this._g = 0;
        this._height = height;
        this._width = width;
        this._end_height = height;
        this._end_width = width;
        this._opacity = opacity;
        this._end_opacity = 1;
        this._rotation = rotation;
        this._end_rotation = rotation;
        this._letter = letter;
        this._punctuation = punctuation;
        this._loaded = false;
        this.img = new Image();
        this.img.src = img;

        this.img.onload = function() {
            this._loaded = true;
            this.draw();
            let ratio = this.img.naturalWidth / this.img.naturalHeight;
            this._height = this._height / ratio;
        }.bind(this);

        this._shaken = false;
    }

    draw() {        
        this._ctx.save();
        this._ctx.translate(this._x + this._width / 2, this._y + this._height / 2);
        this._ctx.globalAlpha = this._opacity;
        this._ctx.imageSmoothingEnabled = true;
        this._ctx.imageSmoothingQuality = "high"
        this._ctx.rotate(this._rotation * Math.PI/180);
        this._ctx.translate(-this._x - this._width / 2, -this._y - this._height / 2);
        this._ctx.drawImage(this.img, this._x, this._y, this._width, this._height);
        this._ctx.restore();
    }

    shake() {
        this._shaken = true;
        this._g = 0.3 + Math.floor(Math.random() * 0.9);
        this._vx = (Math.floor(Math.random() * 90) - 45)
    }

    resize() {
        this._shaken = true;
        this._vx = (Math.floor(Math.random() * 10) - 5)
        this._g = 1.3;
    }

    init() {
        if(!this._shaken) {
            this._x = Math.random() * window.innerWidth;
            this._y = Math.random() * window.innerHeight;

            this._height = this._height * 1;
            this._width = this._width * 1; 
            this._rotation = Math.random() * 360;
            let randomInt = 0;
            if (!this._punctuation) {
                randomInt = Math.random() * 5;
            }
        
            TweenMax.to(this, 1, {
                _x: this._end_x,
                _y: this._end_y + randomInt,
                _height: this._end_height,
                _width : this._end_width,
                _rotation: this._end_rotation,
                _opacity : this._end_opacity,
                delay: Math.random() * 1,
                ease: Expo.easeInOut
            });
        }
    }

    update(rotation) {
        this._vy += this._g;
        this._x += this._vx;
        this._y += this._vy;
        this.draw();
    }
};
